import * as React from "react"
import javaTrainingImg from "../img/java-logo.png"
import nodejsTrainingImg from "../img/node-logo.png"
import phpTrainingImg from "../img/php-logo.png"
import reactTypescriptTrainingImg from "../img/react-typescript-logo.png"
import csharpTrainingImg from "../img/csharp-logo.png"
import kotlinTrainingImg from "../img/kotlinLogo.png"
import pythonTrainingImg from "../img/python-logo.jpg"
import trainingChoice from "../content/trainingChoice.yaml"
import "./trainingChoice.scss"
import { Link } from "gatsby"
import qualiopiCertification from "../documents/certification-qualiopi.pdf"

export const TrainingChoice = () => {
  const images = {
    phpTrainingImg,
    pythonTrainingImg,
    javaTrainingImg,
    kotlinTrainingImg,
    nodejsTrainingImg,
    reactTypescriptTrainingImg,
    csharpTrainingImg,
  }

  return (
    <section className="trainings-section ptb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-18 col-md-8">
            <div className="section-heading text-center mb-5">
              <h3>
                La formation <i>TDD, Clean Architecture et DDD</i> la plus
                avancée du marché
              </h3>
              <h4>
                Voir les{" "}
                <a href="/temoignages" target="_blank">
                  témoignages authentiques
                </a>
              </h4>
              <p
                className="lead"
                style={{ fontSize: "0.9rem", whiteSpace: "no-break" }}
              >
                <a href="/#fondateur" target="_blank">
                  Michaël AZERHAD
                </a>{" "}
                a formé + de <strong>2000 développeurs </strong>
                depuis 2019 !
                <br />
                Avec plus de 1000 heures de formation au compteur, nous
                affichons un taux de satisfaction de 99 % : nos stagiaires sont
                ravis car leurs objectifs pédagogiques sont atteints. Aucun
                abandon à ce jour !
              </p>
            </div>
          </div>
        </div>
        <div className="row criteria-band justify-content-center p-0 m-0">
          <div className="col-12">
            <p>
              WealCome est un organisme de formation{" "}
              <a
                href={qualiopiCertification}
                target="_blank"
                rel="noopener noreferrer"
              >
                certifié Qualiopi !
              </a>
              <br />
              Ce qui permet le{" "}
              <span style={{ color: "#28a745" }}>
                remboursement de votre formation WealCome
              </span>{" "}
              grâce à vos OPCO (Opco-Atlas, FIF PL, FNE, etc.)
            </p>
          </div>
          <div className="col-xs-12 col-sm">
            <div>
              <i className="lni lni-pin" />
              <i className="lni lni-screen" />
            </div>
            <p>
              Mode présentiel <br /> ou mode Visio (Zoom)
            </p>
          </div>
          <div className="col-xs-12 col-sm">
            <div>
              <i className="lni lni-network" />
            </div>
            <p>
              Entre 3 et 12 personnes, professionnels (intra ou
              inter-entreprises) comme particuliers
            </p>
          </div>
          <div className="col-xs-12 col-sm">
            <div>
              <i className="lni lni-emoji-smile" />
              &nbsp;
            </div>
            <p>
              Cible les algorithmes de la vraie vie, plus formateurs que des
              Kata ! &nbsp;<span className="badge badge-success">Unique !</span>
            </p>
          </div>
        </div>
        <div className="jumbotron" style={{ margin: 0, paddingTop: 0 }}>
          <h5>
            Cliquez sur la vignette correspondant à la variante de votre choix !
          </h5>
          <hr className="my-4" />
          <div className="row equal justify-content-start mt-5">
            {(trainingChoice.items || []).map((data) => {
              return (
                <div
                  className="col-md-4 col-lg-2 training mt-2"
                  key={data.item.title}
                >
                  {data.item.new && (
                    <span style={{ color: "green" }}>
                      <strong>Nouvelle date !</strong>
                    </span>
                  )}
                  <h5>{data.item.title}</h5>
                  <Link to={data.item.link}>
                    <div className="single-promo single-promo-hover text-center">
                      <div className="text-center m-0 p-0">
                        <img
                          src={images[data.item.image]}
                          alt="promo"
                          className="img-fluid"
                        />
                        <div className="info">Voir la fiche complète</div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <div className="col">
            <p>
              Nous remercions la société&nbsp;
              <a href="https://www.linkedin.com/company/icpf-&-psi/">ICPF</a>{" "}
              pour nous avoir audités en vue de l'obtention de la certification
              Qualiopi.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
