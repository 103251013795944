import * as React from "react"
import "../components/landingpage/desc-formation.scss"
import pythonLogo from "../img/python-logo.jpg"
import {CommonLayoutFormation} from "../components/common-layout-formation"
import programmePDF20Jui from "../documents/programme - Le TDD et la Clean Architecture - Python - 27012025.pdf"

const metaTitle = "Formation TDD, Clean Architecture et DDD avec Python | WealCome"
const metaDescription =
    "Formez-vous au TDD, Clean Architecture et DDD avec Python" +
    " en participant aux sessions de formation WealCome par Michaël AZERHAD."

export default () => {
    return (
        <CommonLayoutFormation
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            title="Formation TDD, Clean Architecture et DDD dans le monde Python"
            PodcastPresentation={() => null}
            TextDescription={TextDescription}
            Schedule={() => (
                <strong>Lundi 27 janvier - 09h15 / 17h00 <br/>et
                    mardi 28 janvier 2025 - 09h15 / 17h00</strong>
            )}
            ImagesTechnosLogo={() => (
                <>
                    <div>
                        <img
                            src={pythonLogo}
                            alt="Python"
                            className="img-fluid"
                            width="40%"
                            height="40%"
                        />
                    </div>
                </>
            )}
            trainingType="Python"
            programmePDFs={[programmePDF20Jui]}
        />
    )
}

const TextDescription = () => {
    return (
      <>
          <h3>Descriptif</h3>
          <p>Les backends comme les frontends souffrent d’une complexité accidentelle dans la plupart des projets.</p>

          <p>Une complexité accidentelle est une complexité additionnelle malvenue qui aurait pu être évitée.</p>

          <p>
              Quand s’observe-t-elle réellement ?<br/>
              Quelques mois après le début du projet.
          </p>

          <p>
              Pourquoi donc le terme “accidentelle” et non le terme “additionnelle” ?<br/>
              Car bien souvent, elle survient par surprise, sans anticipation aucune.<br/>
              Surtout lorsque le développeur n’est pas aguerri sur les concepts théoriques relatifs à la conception
              logicielle.
          </p>

          <p>Quelles en sont ses conséquences :</p>
          <ul>
              <li>- Un temps de développement considérablement augmenté pour la moindre fonctionnalité normalement simple.</li>
              <li>- Une peur extrême de changer le code existant, de peur d’y engendrer des impacts non perçus ni maîtrisés.</li>
              <li>- Par cette peur de changer/casser, chaque bug remonté est corrigé avec des contournements (workarounds) ; autrement dit des « pansements » indirects proches de la supercherie.</li>
              <li>- Une expressivité du code réduite à néant, par à-coups de ces contournements et du stress engendré par la soumission face au code existant.</li>
              <li>- Un code qui devient de moins en moins testable, par des raccourcis contraires aux bonnes pratiques d’architecture et de conception logicielle, souvent passés inaperçus, sans prise de conscience.</li>
              <li>- Des sessions fatigantes de débogage à outrance devant ce code jugé farfelu, non expressif et souvent bancal.</li>
              <li>- Une application devenue très rapidement inflexible, dont même la moindre évolution technologique, telle une mise à jour de frameworks, devient le signe d’un besoin de refonte globale …</li>
          </ul>

          <p>
              Est-ce une fatalité ?<br/>
              Devant le nombre de projets dans ce cas-là, il faut croire que oui.
          </p>

          <p>
              Mais il existe heureusement des pratiques logicielles qui redonnent de l’espoir et qui atténuent
              drastiquement cette complexité accidentelle, si bien comprises et bien menées.
          </p>

          <p>
              Parmi ces pratiques, je mettrai l’accent sur deux d’entre-elles qui me paraissent totalement cruciales et
              qui ont changé mon quotidien dès 2011 :
          </p>

          <ul>
              <li>- Le Test-Driven Development (TDD)</li>
              <li>- La Clean Architecture (cf. Architecture Hexagonale ou Ports/Adapters).</li>
          </ul>

          <p>
              Dans cette formation, je vous démontrerai comment réaliser un backend from scratch avec ces pratiques
              dans un live coding avancé et fluide, sans manquer de vous exposer une bonne stratégie
              de tests combinant tests d’acceptation, tests unitaires, tests d’intégration et tests end-to-end.
          </p>

          <p>
              Mon premier souhait : que vous compreniez bien que le TDD n’est pas une technique de test mais de codage
              permettant une amélioration notable de vos algorithmes et de vos designs.
          </p>

          <p>
              Le sujet sera un projet Python avec FastAPI, bien plus proche de ce qu’on attend de nous en entreprise qu’un simple kata de tri de nombres.
          </p>

          <p>Technologies :</p>
          <ul>
              <li>- Python</li>
              <li>- FastAPI (REST APIs)</li>
              <li>- SQLAlchemy (ORM)</li>
              <li>- PostgreSQL</li>
              <li>- Pytest (tests unitaires, intégration)</li>
              <li>- TestContainers (Docker pour tests)</li>
          </ul>

          <p>
              Ce sera interactif avec des exercices sur le chemin, des échanges de questions/réponses au tac au tac, et
              surtout une bonne ambiance, à la fois professionnelle et détendue.
          </p>

          <p>
              Ayant l’habitude d’enseigner sur les sujets Craft, j’ai acquis une pédagogie qui vous plaira et qui ne
              laissera personne sur le carreau.
          </p>

          <p>
              Aussi, j’assurerai un suivi sous forme de réponses à vos nouvelles questions post-formation de sorte à ce
              que chacun d’entre vous évolue et progresse sans blocage et dans la bonne direction au quotidien.
          </p>

          <p>
              Pour finir, vous constaterez pour beaucoup que quasiment tout ce que vous pensiez au sujet de ces
              pratiques est en réalité une fausse croyance ...
          </p>

          <p>
              Je n'ai plus qu'à vous souhaiter la bienvenue dans ce noble monde du développement logiciel professionnel.
          </p>

          <p><i>Les sessions regroupent entre 3 et 11 personnes, afin de garder une haute qualité d’interaction.</i></p>

          <div className="program">
              <h2>Programme et méthode pédagogique</h2>
              <h3>Objectif du parcours pédagogique</h3>
              <ul>
                  <li>
                      Sensibilisation à la discipline TDD et à la Clean Architecture dans
                      le monde Python afin de prévenir la complexité accidentelle, y compris l’introduction aux notions principales du DDD (Domain-Driven Design) et de CQRS.
                  </li>
                  <li>
                      Montée en compétences au TDD et à la Clean Architecture dans le
                      monde Python à travers un live coding très professionnel et approfondi, à vocation de démonstration
                      et de mises en situation pour les exercices.<br/>
                      Il s’agira de réaliser from scratch une application digne de ce qu’on attend en entreprise.
                  </li>
                  <li>
                      Compréhension et démonstration des différents types de tests
                      (unitaire / acceptation / end-to-end / intégration).
                  </li>
                  <li>
                      Clarification de chacun des concepts maîtres et annexes à travers
                      des séances de questions-réponses et autres démonstrations
                      concrètes.
                  </li>
              </ul>
              <h3>Durée</h3>
              <p>14 heures</p>
              <h3>Date</h3>
              <p>
                  <strong>Lundi 27 janvier - 09h15 / 17h00 <br/>et
                      mardi 28 janvier 2025 - 09h15 / 17h00</strong>
              </p>
              <h3>Lieu</h3>
              <p>À distance en visioconférence par Zoom.</p>

              <h3>Public cible</h3>
              <p>Particuliers et professionnels :</p>
              <ul>
                  <li>Technical Leader</li>
                  <li>Développeur Backend</li>
                  <li>Développeur Full Stack</li>
                  <li>Architecte Technique</li>
              </ul>
              <h3>Pré-requis</h3>
              <ul>
                  <li>Bonne maîtrise de Python</li>
                  <li>Notions du framework FastAPI et du concept d'injection de dépendances</li>
                  <li>Bonnes connaissances en Programmation orientée objet</li>
                  <li>Capacité à écrire un simple test unitaire avec Pytest</li>
              </ul>
              <h3>Déroulement du parcours pédagogique</h3>
              <h4>Jour 1</h4>
              <ul>
                  <li>Rapide tour de table, présentation de chacun et exposition des attentes</li>
                  <li>Introduction et cours théorique sur le TDD, cassant les énormes quiproquos à son sujet</li>
                  <li>
                      Si le groupe est vraiment novice en TDD, kata éventuel et judicieusement choisi de mises en
                      pratique avec Python et Pytest
                  </li>
                  <li>Introduction et cours théorique sur la Clean Architecture</li>
                  <li>
                      Démarrage d'écriture d'une application "from scratch" digne d'un cas
                      réel d'entreprise en TDD (User Story avec plusieurs règles de gestion) tout en respectant la
                      Clean Architecture dans le monde Python.<br/>
                      Approche agile avec le mindset NoEstimates initiée par un mini atelier BDD.<br/>
                      Notions DDD principales expliquées (Aggregates, Value Objects, Repositories,
                      Factories (au sens DDD), Bounded Contexts, Domain Events).<br/>
                      Approche CQRS (séparation lecture et écriture).
                  </li>
                  <li>Séances de questions/réponses tout au long</li>
              </ul>
              <h4>Jour 2</h4>
              <ul>
                  <li>Suite du live coding de l'application "from scratch" digne d'un cas réel d'entreprise</li>
                  <li>
                      Séances de refactoring continu du code ET des tests de
                      l'application, rendues plaisantes et sans crainte grâce au TDD
                  </li>
                  <li>
                      Clarification de concepts subtils relatifs au TDD et à la Clean Architecture
                  </li>
                  <li>
                      Liaison à une base de données PostgreSQL avec SQLAlchemy - démonstration de tests d'intégration<br/>
                      Utilisation de TestContainers pour assurer un environnement de test reproductible
                  </li>
                  <li>
                      Exposition des services réalisés sous forme d'API REST avec FastAPI et tests end-to-end
                  </li>
                  <li>Séance de questions/réponses tout au long</li>
              </ul>
              <h3>Capacités développées</h3>
              <ul>
                  <li>Maîtrise de l'approche TDD pour résoudre des problèmes algorithmiques</li>
                  <li>Maîtrise de l'écriture de tests unitaires, d’acceptation, d’intégration et end-to-end</li>
                  <li>Maîtrise du concept d'inversion de dépendances (DIP)</li>
                  <li>Maîtrise de la Clean Architecture (Architecture Hexagonale) et d’une arborescence de fichiers optimale</li>
                  <li>Capacité à relier une base de données avec SQLAlchemy dans un contexte de Clean Architecture, sans la violer</li>
                  <li>
                      Capacité à refactorer des tests de manière astucieuse (chapitrage, nommage, D.R.Y.), essentielle pour évoluer sereinement en TDD
                  </li>
                  <li>Bonnes notions des concepts DDD principaux et de CQRS</li>
              </ul>
              <h3>Démarche et méthodes proposées</h3>
              <p>
                  La formation se présentera sous la forme d'une alternance entre
                  découverte de concepts théoriques, annihilation de fausses croyances,
                  démonstrations complètes par du live coding, exercices et échanges constants avec les
                  participants.
              </p>
              <p>
                  L'outil de communication requis pour cette formation est l’alternance
                  entre l'oral, l'écrit, le partage d'écran en visioconférence (audio/vidéo) et la
                  manipulation d'un éditeur de code.
              </p>
              <h3>Les moyens pédagogiques</h3>
              <ul>
                  <li>Des apports théoriques sur le processus</li>
                  <li>Des exemples concrets</li>
                  <li>Des démonstrations complètes par le formateur en live coding</li>
                  <li>Exercices réalisés en live par les participants sur une application type entreprise</li>
                  <li>Challenges proposés quant au TDD, à la Clean Architecture et au refactoring de code</li>
              </ul>
              <h3>Évaluation formative</h3>
              <p>
                  Une feuille d'évaluation de compétences est remise à chaque stagiaire
                  leur permettant d'évaluer leurs acquis.
              </p>
              <p>
                  Une deuxième évaluation permet d'évaluer les modalités pédagogiques et
                  le contenu de la formation, de mesurer le degré de satisfaction « à chaud » du stagiaire, afin d’adapter les formations suivantes aux besoins
                  spécifiques des participants.
              </p>
              <h3>Documents et supports pour les stagiaires</h3>
              <p>
                  À l’issue de cette session, il sera remis à chaque stagiaire le
                  support de formation, le projet Git réalisé ensemble, ainsi qu’une
                  attestation de réalisation de l’action de formation.
              </p>
              <h3>Encadrement pédagogique</h3>
              <p>
                  La formation sera assurée par{" "}
                  <a href="/#fondateur" target="_blank" rel="noopener noreferrer">
                      Michaël AZERHAD
                  </a>{" "}
                  <br/>
                  Profil et qualité : Président de WealCome et expert technique avec plus de 15 ans d'expérience
              </p>
              <h3>Modalités d'accès</h3>
              <p>
                  Contactez-nous au <a href="tel:+33609885102">06 09 88 51 02</a> ou par
                  mail :&nbsp;
                  <a href="mailto:contact@wealcomecompany.com">
                      contact@wealcomecompany.com
                  </a>
              </p>
              <h3>Délais d'accès</h3>
              <p>
                  Nous pouvons programmer les formations en fonction de vos contraintes et de
                  nos disponibilités.
              </p>
              <p>
                  Nos formations sont accessibles aux personnes en situation de
                  handicap.
                  <br/>
                  Les aspects, l’accessibilité et le type de handicap au regard des
                  modalités pédagogiques sont à évoquer impérativement au cours de
                  l’entretien préalable à toute contractualisation afin de pouvoir
                  orienter ou accompagner au mieux les personnes en situation de
                  handicap.
              </p>
          </div>
      </>
    );
};